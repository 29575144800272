import { Component } from '../../../libs/components';
import Chart from 'chart.js/auto';

type StrategyName = string;

interface ChartData {
  label: string;
  asset_type_id: number;
  value: number;
  color: string;
}

interface ChartData {
  strategy_name: StrategyName;
  data_descartes: ChartData[];
  data_others: ChartData[];
}


export default class ChartsComponent extends Component {
  document = this.host;
  chartData: ChartData[];
  chartTypes: StrategyName[];
  selectedStrategyName: StrategyName;

  buildButtons() {
    const buttonContainer = document.getElementById('chart-buttons');
    this.chartTypes.forEach((type) => {
      const button = document.createElement('button');
      button.innerHTML = type;
      button.id = 'chart-button-' + this.chartTypes.indexOf(type);
      button.classList.add('button-list__button');
      button.addEventListener('click', () => this.onClick(type));
      buttonContainer.appendChild(button);
    });
  }

  getData(param: string) {
    const found: ChartData[] = this.chartData.find(
      (d) => d.strategy_name === this.selectedStrategyName
    )[param];

    return {
      labels: found.map((d) => d.label),
      datasets: [
        {
          borderWidth: 0,
          data: found.map((d) => d.value),
          backgroundColor: found.map((d) => d.color),
        },
      ],
    };
  }

  updateChart() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      animation: {
        duration: 1000,
        easing: 'easeOutQuad',
      },
      cutout: '75%',
    };

    const canvasContainer1 = document.getElementById('chart1');
    const canvasContainer2 = document.getElementById('chart2');

    const _canvas1 = document.getElementById('chart1-canvas');
    const _canvas2 = document.getElementById('chart2-canvas');

    canvasContainer1.removeChild(_canvas1);
    canvasContainer2.removeChild(_canvas2);

    const canvas1 = document.createElement('canvas');
    const canvas2 = document.createElement('canvas');

    canvas1.id = 'chart1-canvas';
    canvas2.id = 'chart2-canvas';

    new Chart(canvas1, {
      type: 'doughnut',
      data: this.getData('data_descartes'),
      options,
    });

    new Chart(canvas2, {
      type: 'doughnut',
      data: this.getData('data_others'),
      options,
    });

    canvasContainer1.appendChild(canvas1);
    canvasContainer2.appendChild(canvas2);
  }

  updatePercentage() {
    const value1 = document.getElementById('chart1-value');
    const value2 = document.getElementById('chart2-value');

    const selected: ChartData = this.chartData.find(
      (d) => d.strategy_name === this.selectedStrategyName
    );

    value1.innerHTML =
      selected.data_descartes.find((d) => d.asset_type_id === 1).value + '%';

    value2.innerHTML =
      selected.data_others.find((d) => d.asset_type_id === 1).value + '%';
  }

  updateButtons() {
    const selectedId = this.chartTypes.indexOf(this.selectedStrategyName);

    this.chartTypes.forEach((t) => {
      const id = this.chartTypes.indexOf(t);
      const button = document.getElementById('chart-button-' + id);

      if (id === selectedId)
        button.classList.add('button-list__button--active');
      else button.classList.remove('button-list__button--active');
    });
  }

  updateLegend() {
    const legendContainer = document.getElementById('chart-legend');
    legendContainer.innerHTML = '';
    const displayedData = this.chartData.find(
      (d) => d.strategy_name === this.selectedStrategyName
    );

    displayedData.data_descartes.forEach((d) => {
      const legendItem = document.createElement('div');
      legendItem.classList.add('charts__legend-item');
      legendItem.innerHTML = `
          <div class="charts__legend-item">
            <div 
              class="charts__legend-item__color" 
              style="background-color: ${d.color}">
            </div>
            <div 
              class="charts__legend-item__label">
              ${d.label}
            </div>
          </div>
        `;
      legendContainer.appendChild(legendItem);
    });
  }

  onClick(strategy_name: StrategyName) {
    this.selectedStrategyName = strategy_name;
    this.updateButtons();
    this.updateChart();
    this.updatePercentage();
    this.updateLegend();
  }

  setData(data: ChartData[], types: StrategyName[], defaultType: StrategyName) {
    this.chartData = data;
    this.chartTypes = types;
    this.buildButtons();
    this.onClick(defaultType || types[0]);
  }

  onInit() {
    const dataHolderEl = document.getElementById('allocation-data');
    const data = JSON.parse(dataHolderEl.textContent);
    const types = data.map((x) => x.strategy_name);
    const defaultType = types.at(2);

    this.setData(data, types, defaultType);
  }
}

ChartsComponent.declare('.charts');
